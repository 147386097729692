import React from 'react'

export const MadeByEnigmaLogo = () => {
    return (
        <div>
            <svg className="w-10" id="Livello_1" viewBox="0 0 76 75" xmlns="http://www.w3.org/2000/svg">);
                <defs>
                    <linearGradient x1="84.766%" y1="0%" x2="38.195%" y2="78.067%" id="a"><stop stopColor="#B942B8" offset="0%"></stop><stop stopColor="#CA83F1" offset="100%"></stop></linearGradient><linearGradient x1="80.316%" y1="20.397%" x2="30.656%" y2="76.69%" id="b"><stop stopColor="#D42200" stopOpacity=".677" offset="0%"></stop><stop stopColor="#F76F00" stopOpacity=".765" offset="100%"></stop></linearGradient></defs><g transform="translate(0 3)" fill="none" fillRule="evenodd"><rect fill="#FFF" x="27" width="46" height="46" rx="17"></rect><circle fill="url(#a)" cx="26.5" cy="45.5" r="26.5"></circle><rect stroke="#000" strokeWidth="5" fill="url(#b)" x="27" width="46" height="46" rx="17"></rect></g></svg>
        </div>
    )
}
