import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"


const Header = ({ siteTitle, logoText, pageId }) => (
  <div className="border-b border-gray-200 py-3 w-full fixed bg-white bg-opacity-60  glassbox-non-radius z-30">
    <div className="flex justify-between items-center px-4">
        <div className="flex items-center">
          <a href="/">
            <img alt="Emailkick Logo" className="w-32" src={'/logo-full.png'} />
          </a>
            <span className="ml-3 text-base">{logoText}</span>
            {/* <span className="ml-3 text-base">Docs</span> */}
        </div>
        <div>
            <div className="flex items-baseline display big sm-hide xs-hide">
              <a href="/email-designer/" class={`pointer strong animate mr3 quiet link-unquiet scroll-down hover:underline ${pageId == 'emaildesigner' ? 'text-gray-900 font-bold' : 'quiet'}`}>Email Designer</a>

                <a href="/email-api/" class={`pointer strong animate mr3 quiet link-unquiet scroll-down hover:underline ${pageId == 'api' ? 'text-gray-900 font-bold' : 'quiet'}`}>Mail API</a>
                <a href="/pricing/" class={`pointer strong animate mr3 quiet link-unquiet scroll-down hover:underline ${pageId == 'pricing' ? 'text-gray-900 font-bold' : 'quiet'}`}>Pricing</a>
                {/* <a href="/letters" class={`pointer strong animate mr3  link-unquiet scroll-down hover:underline ${pageId == 'letters' ? 'text-gray-900 font-bold' : 'quiet'}` }>Letters</a> */}

                <a href="/docs/" class={`pointer strong animate mr3  link-unquiet scroll-down hover:underline ${pageId == 'docs' ? 'text-gray-900 font-bold' : 'quiet'}` }>Docs</a>
                <a href="/email-inspirations/" class={`pointer strong animate mr3  link-unquiet scroll-down hover:underline ${pageId == 'ei' ? 'text-gray-900 font-bold' : 'quiet'}` }>Email Inspirations</a>
                <a href="/demo/" className="pointer text-blue-800 strong animate mr3 quiet link-unquiet hover:underline scroll-down">Schedule a demo</a> 
                <a rel='nofollow'  href="https://app.emailkick.com/" className="pointer strong animate mr3 quiet link-unquiet hover:underline scroll-down">Login</a> 
                <a rel='nofollow' href='https://app.emailkick.com/register/' class="rounded-md pointer strong animate mr3 link-unquiet hover:underline scroll-down px-4  py-2 bg-red-500 hover:bg-red-400 text-white">
                Register for free
                </a>
            </div>
        </div>
    </div>
    </div>
)

const Header2 = ({ siteTitle }) => (
  <header className="px3 col-12 header">
    <div className="max-width-4 mx-auto flex justify-between items-center top-nav-bar">
      <a href="/" class="py3 flex justify-between items-center">
        <img className="logoIcon" src={'/logo-full.png'} />
        {/* <span className="logoText">emailkick</span> */}
      </a>
     

      <div className="flex items-baseline display big lg-huge sm-hide xs-hide py3">
        <a href="/email-api" class="pointer strong animate mr3 quiet link-unquiet scroll-down">Mail API</a>
        <a href="/pricing" class="pointer strong animate mr3 quiet link-unquiet scroll-down">Pricing</a>
        <a href="https://app.emailkick.com/" className="pointer strong animate mr3 quiet link-unquiet scroll-down">Login</a> 
        <a href='http://app.emailkick.com/register' class="pointer strong animate mr3 link-unquiet scroll-down ed-btn primary-cta">
         Register for free
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
