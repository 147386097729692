/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css";
import { MadeByEnigmaLogo } from "./MadeByEnigmaLogo";
//import '../assets/css/style.css';
import CookieConsent from 'react-cookie-consent';


const footerMenuItems = {
  'rowOne': [
    { 
      id:'tc',
      name: 'Terms and Conditions',
      href: '/terms/'
    },
    { 
      id:'pr',
      name: 'Privacy Policy',
      href: '/privacy/'
    },
    { 
      id:'gdpr',
      name: 'GDPR',
      href: '/gdpr/'
    }
  ],
  'rowTwo': [
    { 
      id:'guide-0',
      name: 'Get Started',
      href: '/docs/'
    },
    { 
      id:'guide-1',
      name: 'Connect Emailkick with SES',
      href: '/docs/how/link-emailkick-with-aws-ses/'
    },
    { 
      id:'guide-2',
      name: 'Send using Emailkick API',
      href: '/docs/how/sending-email-using-api/'
    }
  ],
  'rowThree': [
    { 
      id:'product-0',
      name: 'Drag and drop email designer',
      href: '/email-designer/'
    },
    { 
      id:'product-1',
      name: 'Template manager for Amazon SES',
      href: '/ses-template-manager/'
    },
    { 
      id:'product-2',
      name: 'Email API',
      href: '/email-api/'
    },
    { 
      id:'product-4',
      name: '💌 Email Inspirations',
      href: '/email-inspirations/'
    }
  ]
}

const Layout = ({ children, logoText, pageId }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="emailDriveLand">
      <Header logoText={logoText} pageId={pageId} siteTitle={data.site.siteMetadata.title} />
      <div
        
      >
        <main className="mb-28 overflow-x-hidden">{children}</main>
        <footer className="w-full bottom-0 left-0 bg-gray-50  shadow-inner pt-8 pb-8 md:px-12 px-4 border-t border-gray-100 mt-16">
          <div className="w-full flex flex-col md:flex-row max-w-6xl mx-auto">
            <div className="w-full flex-1">
              <ul className="">
                <li><div className="text-xs text-gray-400 mb-4">Product</div></li>
                { footerMenuItems['rowThree'].map((item) => <li className="pb-2"><a className="text-gray-500 hover:text-gray-800 hover:underline text-xs" href={item.href}>{item.name}</a></li> ) }
              </ul>
            </div>

            <div className="w-full flex-1">
              <ul className="">
                <li><div className="text-xs text-gray-400 mb-4">Informations</div></li>
                { footerMenuItems['rowOne'].map((item) => <li className="pb-2"><a className="text-gray-500 hover:text-gray-800 hover:underline text-xs" href={item.href}>{item.name}</a></li> ) }
              </ul>
            </div>

            <div className="w-full flex-1">
              <ul className="mt-8 md:mt-0">
                <li><div className="text-xs text-gray-400 mb-4">Guides</div></li>
                { footerMenuItems['rowTwo'].map((item) => <li className="pb-2"><a className="text-gray-500 hover:text-gray-800 hover:underline text-xs" href={item.href}>{item.name}</a></li> ) }
              </ul>
            </div>
            <div className="w-full flex-1">
              <div className="w-full mt-8 md:mt-0 md:text-right flex md:items-end md:justify-end text-xs flex-col">
                <div className="flex items-center"><MadeByEnigmaLogo /> <a href="https://madebyenigma.com" className="text-gray-700 ml-3 hover:underline">Made by Enigma</a></div>
                <div className="mt-4 text-gray-400"> © {new Date().getFullYear()} Emailkick by Enigma Software Solutions Private Limited</div>

              </div>
            </div>
          </div>
          {/* <div className="max-width-4 mx-auto footer-flex">

        
            <div className="flex items-baseline display lg-huge py3">
                <a href="/privacy" className="pointer animate mr3 quiet link-unquiet scroll-down">Privacy Policy</a>
                <a href="/terms" className="pointer animate mr3 quiet link-unquiet scroll-down">Terms and Condition</a>
            </div>
            © {new Date().getFullYear()} Enigma Software Solutions Private Limited
          </div> */}
        
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          containerClasses="w-64"
          buttonWrapperClasses="w-full flex justify-end"
          style={{ width:'300px', left:'12px', marginBottom:'12px', borderRadius:'4px', fontSize:'12px', backgroundColor:"rgba(24,24,24,0.9)"  }}
          buttonStyle={{ fontSize:'13px', borderRadius:'4px', marginTop:'2px' }}
          buttonClasses="hover:bg-yellow-800"
          cookieName="gatsby-gdpr-google-analytics">
<p className="text-sm text-gray-300 ">This website stores cookies on your computer to ensure you get the best experience on our website.
</p></CookieConsent>

      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
